img {
	max-width: 100%;
	height: auto;
}

.fr-view,
.fr-view p {
	font-size: 16px;
	line-height: 1.7;
}

span.highlight {
	display: inline-block;
	background-color: #ffff6f;
}
